const TitleSVG = () => (
  <svg
    className="w-[80%] max-w-[583px] md:w-[100%]"
    width="590"
    height="69"
    viewBox="0 0 590 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid"
  >
    <path
      d="M12.0315 21.9736L13.2815 48.2236"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M3.76406 21.3509C1.35082 20.6297 8.6489 20.7282 11.0954 20.7282C16.4249 20.7282 21.7269 21.1089 27.0315 21.9736"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M35.9689 21.3428C35.1492 21.4017 35.5253 46.2636 33.9259 49.9442"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M55.3771 22.3643C54.6325 22.4533 54.1262 32.4261 54.0134 33.5598C53.6051 37.6635 52.9338 41.7543 53.6485 45.8583"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M38.0119 33.6011C41.5758 36.1404 46.3841 34.4136 50.2696 35.644"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M66.6133 22.3643C65.8514 22.5759 66.0955 31.2436 66.0953 31.2451C65.6853 36.456 65.2046 41.6823 66.0953 46.8798"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M65.5919 22.3643C65.7335 21.273 71.8461 21.6785 72.5851 21.7022C76.6282 21.832 80.1062 22.8965 83.9785 21.3428"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M67.9607 47.7549C66.341 47.7549 71.202 47.9761 72.8182 47.8748C75.4873 47.7076 78.3199 46.315 80.914 47.1383"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M67.6599 35.9717C67.2364 36.4613 72.2989 36.5943 72.5086 36.6098C75.9381 36.862 78.6321 36.2004 81.9355 35.6436"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M293.625 43.4467C293.501 44.4109 315.305 43.2289 318.14 42.7939"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M342.655 43.8153C342.655 40.1243 342.788 36.2639 343.093 32.5918C343.544 27.1551 344.698 21.6994 344.698 16.2354"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M362.064 43.8153C361.59 43.7581 361.344 42.4458 361.117 42.0402C359.454 39.0712 357.687 36.1908 356.094 33.1645C354.23 29.6238 352.174 26.1766 350.318 22.6258C349.214 20.5151 347.786 17.5678 345.72 16.2354"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M364.106 43.8153C364.106 41.064 364.302 38.2267 364.555 35.499C364.858 32.2314 365.119 28.9572 365.42 25.6881C365.688 22.7696 366.278 20.1886 366.124 17.2568"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M375.343 18.2788C381.146 18.2788 386.906 18.2788 392.708 18.2788"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M375.343 17.2568C374.181 17.4076 374.557 25.4337 374.512 26.306C374.228 31.7932 374.346 37.2998 374.346 42.7939"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M375.344 43.8159C375.224 44.5806 382.704 44.6839 383.927 44.7757C388.697 45.1338 393.134 43.8159 397.815 43.8159"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M376.641 32.0472C376.162 31.9883 376.383 31.7368 376.717 31.6355C377.822 31.3001 379.198 32.1467 380.285 32.3092C385.216 33.0457 389.857 32.0814 394.751 31.7103"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M439.374 19.3371C438.125 19.3371 437.108 18.851 435.728 19.0779C431.877 19.7112 428.768 22.0513 426.261 24.9266C422.516 29.2212 422.186 36.2298 425.929 40.6587C430.036 45.5182 436.828 46.3491 442.69 44.9897"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M455.969 21.0596C455.969 28.5741 453.671 36.0163 455.969 43.5321"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M459.033 32.0367C459.142 32.6309 459.653 32.0319 459.872 31.9277C462.003 30.9114 464.405 31.3293 466.594 31.5462C469.566 31.8406 472.407 31.9676 475.377 31.5462"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M490.699 42.4182C490.699 42.839 491.259 41.6976 491.386 41.5081C493.522 38.3025 495.657 35.0948 497.786 31.8841C501.11 26.8733 504.177 21.5388 508.064 16.9736"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M507.043 19.0176C507.593 19.0711 507.92 19.3319 508.273 19.7362C510.421 22.1986 511.999 25.1965 513.588 28.009C516.607 33.3526 518.27 39.3161 521.344 44.5546"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M498.871 32.7367C503.129 33.9739 508.846 32.9477 513.172 32.2959"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M404.532 31.263C407.509 32.7771 411.507 31.5212 414.532 30.7236"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M527.547 20.0381C527.162 20.0798 528.387 24.1792 528.422 24.4645C529.161 30.4684 529.052 36.4942 529.516 42.5107"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M528.494 18.3182C528.748 16.0206 535.629 17.3362 537.036 17.4624C540.946 17.8128 547.035 18.2902 547.852 23.3769C548.433 26.9943 543.856 29.9291 541.071 30.965C537.473 32.3028 532.619 34.8914 529.176 32.0109"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M530.537 32.2959C532.99 32.5627 535.955 35.8897 537.743 37.3276C540.775 39.7665 543.611 42.419 546.881 44.5537"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M557.096 20.0381H586.718"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M572.418 22.0811V44.5536"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M293.624 13.1714V43.8158"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M329.376 15.2148V42.7948"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M478.441 19.0176V45.5761"
      stroke="#020231"
      strokeWidth="6.25"
      strokeLinecap="round"
    />
    <path
      d="M148.574 6.02051V63.2234"
      stroke="#DA145E"
      strokeWidth="10"
      strokeLinecap="round"
    />
    <path
      d="M119.973 41.7725H157.767"
      stroke="#DA145E"
      strokeWidth="10"
      strokeLinecap="round"
    />
    <path
      d="M121.124 41.7723C120.163 41.7723 124.845 35.4702 125.045 35.2092C132.674 25.291 141.217 16.1148 148.574 6.02051"
      stroke="#DA145E"
      strokeWidth="10"
      strokeLinecap="round"
    />
    <path
      d="M252.765 6.02051V59.1375"
      stroke="#DA145E"
      strokeWidth="10"
      strokeLinecap="round"
    />
    <path
      d="M223.886 39.249L264.985 36.9657"
      stroke="#DA145E"
      strokeWidth="10"
      strokeLinecap="round"
    />
    <path
      d="M252.765 5C252.037 5 252.066 5.12257 251.652 5.65944C248.781 9.38477 245.556 12.8941 242.358 16.3325C235.437 23.7755 229.447 31.7769 223.142 39.7303"
      stroke="#DA145E"
      strokeWidth="10"
      strokeLinecap="round"
    />
    <path
      d="M206.484 29.5894C206.19 27.7858 205.556 25.9759 205.492 24.1461C204.96 19.6805 204.061 16.7203 202.817 12.3958C201.584 10.1663 200.132 8.33995 198.134 6.88817C193.304 3.38042 188.601 5.38041 183.723 7.879C178.171 10.7225 175.373 16.0552 174.467 22.1645C173.993 25.3521 174.084 28.7675 174.222 31.9849C174.366 35.3603 174.808 39.0426 176.009 42.2068C178.274 48.1721 182.72 51.7181 188.632 53.2564C192.331 54.2186 197.585 54.7409 200.717 52.0022C204.77 48.4583 206.276 42.8078 206.643 37.6164C206.833 34.9261 206.918 32.2607 206.484 29.5894Z"
      stroke="#DA145E"
      strokeWidth="10"
      strokeLinecap="round"
    />
  </svg>
);

export default TitleSVG;
