import classNames from "classnames";

import TitleSVG from "./components/titleSVG";
import GraphSVGDesktop from "./components/graphSVGDesktop";
import GraphSVGMobile from "./components/graphSVGMobile";
import ArrowSVG from "./components/arrowSVG";

const innerContainerClasses = classNames(
  "mt-20 md:mt-32 pb-4xl lg:pb-4xl lg:w-5/6 lg:mx-auto flex flex-col items-center gap-y-xl md:gap-y-2xl"
);

const FourOhFourView = () => {
  return (
    <>
      <main>
        <div className="wrapper text-black-rock relative overflow-hidden">
          <div className={innerContainerClasses}>
            <TitleSVG />

            <GraphSVGDesktop />
            <GraphSVGMobile />

            <div className="mb-6 mt-4 max-w-[85%] text-[16px] leading-[24px] md:mt-0 md:max-w-[735px] md:text-[20px] md:leading-[30px] lg:text-[25px] lg:leading-[37.5px]">
              <div className="flex justify-center md:mb-[-140px]">
                <ArrowSVG />
              </div>
              <p className="font-bold">Ouch!</p>
              <p>This page is not available :(</p>
              <br />
              <p>
                You can check out one of the{" "}
                <a
                  className="text-cerise"
                  href="https://randomcatgifs.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  links
                </a>{" "}
                in the chart to keep exploring, or use the menu at the top of
                the page
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default FourOhFourView;
