const ArrowSVG = () => (
  <svg
    className="pointer-events-none hidden max-w-[100%] origin-top md:block md:translate-x-[-35%] md:scale-[80%] lg:translate-x-[-10%] lg:scale-100"
    width="208"
    height="222"
    viewBox="0 0 208 222"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M140.903 194.816C142.176 192.237 144.289 191.987 145.835 189.721C149.622 184.17 153.461 178.383 156.796 172.543C162.418 162.7 166.203 152.753 167.175 141.447C169.183 118.093 151.482 95.575 135.978 84.68C124.002 76.264 109.248 70.5277 94.7748 67.9114C83.3234 65.8415 70.3637 61.9442 62.141 53.2322C56.0309 46.7587 47.2067 40.0461 49.85 28.621C51.1977 22.7959 52.8412 18.2072 55.4824 12.8555"
      stroke="#DA145E"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeDasharray="5 5"
    />
    <path
      d="M63.2146 24.7854C62.5067 22.2701 62.2604 7.83751 60.4907 6.96412C56.9303 5.20698 45.8836 11.0286 42.0953 11.7377"
      stroke="#DA145E"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
  </svg>
);

export default ArrowSVG;
