import Head from "next/head";
import { NextSeo } from "next-seo";

import Piano from "components/piano/piano";

import { fetchNavbarAndFooter } from "lib/fetchers/common";
import { PIANO_SCRIPT_URL, MIXPANEL_TOKEN } from "lib/constants";

import FourOhFourView from "components/404";
import Navigation from "components/navigation/navigation";
import Footer from "components/footer/footer";
import { useEffect } from "react";
import { datadogLogger } from "lib/datadog/logger";

export async function getStaticProps() {
  const [menuItems] = await Promise.all([fetchNavbarAndFooter()]);

  return {
    props: {
      __initialState: {
        sitewideOption: { ...menuItems },
      },
    },
    revalidate: 300,
  };
}

const FourOhFourPage = () => {
  const title = "404: Page Not Available";
  const description =
    "Sifted offers a wide range of fantastic pages on Startup Europe. However, this page is no longer - or never was - one of them.";
  const seoImage =
    "https://images.sifted.eu/wp-content/uploads/2023/03/09145233/no-cat-wide.png?auto=format,compress&q=75&w=1200";

  useEffect(() => {
    datadogLogger.info(
      `Hit: 404 Page Not Available [${window.location.pathname}]`,
      {
        url: window.location.toString(),
      }
    );
  }, []);

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        openGraph={{
          title,
          description,
          images: [{ url: seoImage }],
        }}
        twitter={{
          handle: "@siftedeu",
          site: "@siftedeu",
          cardType: "summary_large_image",
        }}
      />

      <Head>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Piano pianoScriptUrl={PIANO_SCRIPT_URL} mixpanelToken={MIXPANEL_TOKEN} />

      <Navigation />

      <FourOhFourView />

      <Footer />
    </>
  );
};

export default FourOhFourPage;
